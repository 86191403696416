import tiers from '@/locales/tiers/index.en'
import globals from '@/locales/globals/index.en'

export default {
  tiers,
  globals,
  forms: {
    ShopCopyPayer: 'Copy the information entered for all subsequent buyers',
    EventCopyPayer: 'Copy the information entered for all subsequent participants',
    MembershipCopyPayer: 'Copy the information entered for all subsequent participants',
    requiredField: 'This field is required'
  },
  administration: {
    admin: 'Administrators',
    share: 'my page',
    shareShort: 'Share',
    stats: 'Statistics',
    update: 'the contents',
    updateShort: 'Modify'
  },
  button: {
    choose: 'Choose',
    add: 'Add',
    back: 'Back',
    cancel: 'Cancel',
    copy: 'Copy',
    remove: 'Remove',
    save: 'Save',
    update: 'Modify',
    send: 'Send'
  },
  campaign: {
    currencyUnit: '{0} €',
    projectExpensesDetails: 'Description of expenditure items',
    projectBeneficiaries: 'Final beneficiaries of the collection',
    projectOwners: 'Carriers of the project',
    projectTargetCountry: 'Location of the project',
    fundedAt: 'Funded at <b>{0}%</b> - <b>{1}€</b> / {2}€',
    goal: 'Goal : {0} €',
    aboutOrganism: 'About the organization',
    contribute: 'Contribute to the project',
    saleEndDate: 'End in',
    saleStartDate: 'Start in',
    MembershipMembers: 'Member | Member | Members',
    CrowdFundingMembers: 'Contributor | Contributors',
    EventMembers: 'Participant | Participant | Participants',
    amountCollected: 'collected | collected | collected',
    membershipFrom: 'Valid membership',
    customTierPrice: 'Free amount',
    myPersonalInformations: 'My contact information',
    donation: 'Donation',
    selection: 'Selection',
    myDonation: 'My donation',
    mySummary: 'My summary',
    monthlyTotal: 'Monthly total',
    total: 'Total',
    amountTtc: 'Total including tax :',
    amountTva: 'Including tax :',
    IGiveMonthly: 'I donate every month',
    IGiveOneTime: 'I donate once',
    priceAfterRfReduction: 'Your donation will only cost you <b>{0}€</b> after tax reduction',
    fiscalReduction: 'Tax Reduction',
    fiscalReductionRuban: 'Taxation',
    fiscalReductionDescription:
      'The donation to <b>{0}</b> is eligible for a tax reduction as it meets the general conditions of articles 200 and 238 bis of the general tax code.',
    personalInformationsIsPerson: 'Individual',
    personalInformationsIsCompany: 'Organization',
    logoOf: 'Logo of the association {0}',
    date: {
      noDate: {
        with2Times: 'From {0} to {1}'
      },
      SameDay: {
        with2Times: 'On {0}, from {1} to {2}',
        withEndTime: 'On {0}, until {1}',
        withNoTime: 'On {0}',
        withStartTime: 'On {0}, from {1}'
      },
      with2Dates: {
        with2Times: 'From {0} to {1}, from {2} to {3}',
        withEndTime: 'From {0} to {1}, to {2}',
        withNoTime: 'From {0} to {1}',
        withStartTime: 'From {0} to {1}, starting from {2}'
      }
    },
    error: {
      missingInfo: 'Missing information',
      emptyCart: 'We require your order to contain at least one item',
      fillMandatory: 'Please fill in the required fields',
      fileIsUploading: 'Your file is uploading',
      pleaseWaiting: 'Please wait'
    },
    field: {
      yourEmailAddress: 'Your email address',
      address: 'Address',
      birthday: 'Date of birth',
      city: 'City',
      copyPayer: 'Copy the information entered for all subsequent participants',
      country: 'Country',
      email: 'Email',
      emailConfirmation: 'Email confirmation',
      file: 'Click here to import a file',
      firstName: 'First name',
      lastName: 'Last name',
      organism: 'Company name',
      pay: 'This participant will make the payment',
      zipcode: 'Zip code',
      companySiren: 'SIREN',
      companyLegalStatusId: 'Legal status ',
      paymentDate: {
        nextPayment:
          'In order to validate your credit card, your first charge will be made today. The next one will be on ',
        paymentDay_01: 'You will be charged every',
        paymentDay_02: 'of every month.',
        paymentDayErrorRequired: 'Please enter your first debit day.',
        paymentDayErrorDay: 'Please enter a day between 1 and {0}.'
      }
    },
    nextStep: 'Next step',
    previousStep: 'Previous step',
    order: 'Order',
    report: 'Report the page',
    requiredFields: '* Required fields',
    state: {
      button: 'Access my settings',
      deleted: {
        subtitle: 'To restore it, go to your campaign settings.',
        title: 'This campaign has been deleted!'
      },
      disabled: {
        subtitle: 'To reactivate it, go to your campaign settings.',
        title: 'This campaign is disabled!'
      },
      draft: {
        subtitle: 'To publish it, go to your campaign settings.',
        title: 'This campaign is in draft!'
      }
    },
    step: {
      blockedBanner: {
        paymentHasBeenSuspended: 'Payment or ordering on this campaign is temporarily suspended.',
        giveUsEmailToBeNotified:
          'Tell us your email to be notified when it reopens soon! The {0} association will get back to you soon.'
      },
      amountSelector: {
        noVatRate: 'Contribution not subject to tax',
        lastPayment:
          'In order to validate your credit card, your first charge will be made today. Your last payment will be taken on {0}.',
        paymentDay_01: 'You will be charged every',
        paymentDay_02: 'of every month.',
        vatRate: 'Contribution subject to tax ({0} %)'
      },
      participants: {
        extraOptions: 'Additional options',
        furtherInformations: 'additional information',
        participant: 'Participant',
        participantProfile: 'Participant profile',
        vat: 'VAT',
        type: {
          shop: {
            participant: 'Buyer',
            participantProfile: 'buyer profile',
            action: 'Buy'
          },
          event: {
            participant: 'Participant',
            participantProfile: 'Participant Profile',
            action: 'Participate'
          },
          membership: {
            participant: 'Member',
            participantProfile: 'Contact information',
            action: 'Join'
          },
          crowdfunding: {
            participant: 'Contributor',
            action: 'Contribute'
          },
          donation: {
            participant: 'Contributor',
            action: 'Contribute'
          },
          paymentform: {
            participant: 'Payer',
            action: 'Pay'
          }
        }
      },
      payer: {
        existingAccount: 'I already have a HelloAsso account:',
        furtherInformations: 'additional information',
        login: 'I am logging in',
        logout: 'Change email address',
        finalizeAsOrg: 'Finalize as an organization',
        payAsOrg: 'Pay as an organization',
        title: 'Payment Information',
        titleFree: 'Check your details'
      },
      summary: {
        acceptDocument: 'I accept the following document:',
        acceptGdpr_1: 'I accept the',
        acceptGdpr_2: 'of the service and I have read the',
        acceptGdprLink_1: 'Terms and Conditions',
        acceptGdprLink_2: 'privacy policy',
        contributingToHelloAsso: 'Your contribution to HelloAsso',
        discount: 'discount',
        donationTitle: 'donation',
        donationCrowd: 'Donation',
        donation: 'Additional donation to {0}',
        frequency: {
          installment: 'Payment in {0} installments',
          monthly: 'Monthly payment'
        },
        product: 'Products',
        membership: 'Memberships',
        options: 'Options',
        option: 'Option',
        payment: 'Amount (incl. VAT)',
        total: {
          donation: 'Donation',
          options: 'Options',
          reduction: 'Reduction',
          title: 'Total',
          type: {
            crowdfunding: 'Contributions',
            event: 'Tickets',
            membership: 'Memberships',
            shop: 'Products'
          }
        },
        priceFixed: 'Price set',
        priceFree: 'Free price',
        schedulerHelp:
          'Your options are due on your first payment date. Your discount - if you have one - is applied to your installments. The voluntary contribution you decide to give to HelloAsso is the same for each installment.',
        titleBill: 'Summary',
        titleScheduler: 'Your due dates',
        understandContribution:
          'I understand that HelloAsso is a social and solidarity company and confirm that I want to make a financial contribution, optional and modifiable, by finalizing my payment to ensure free service to the association. *',
        totalContribution: '(So {0} € in total)',
        byTerms: ' per term',
        type: {
          crowdfunding: {
            options: 'Contribution'
          },
          shop: {
            options: 'Products and options'
          },
          event: {
            options: 'Tickets and options'
          },
          membership: {
            options: 'Memberships and options'
          },
          paymentform: {
            options: 'sale amount'
          }
        },
        updateContribution: 'Modify',
        whyContribute: 'Why?'
      },
      ticketingSelect: {
        free: 'free',
        freePrice: 'Free Price',
        from: 'From {0} €',
        yourCrowdFundingItemWillCostOnly:
          'Your donation will only cost you {0}€ after tax reduction',
        yourEventItemWillCostOnly: 'Your ticket will only cost you {0}€ after tax reduction',
        yourShopFundingItemWillCostOnly:
          'Your purchase will only cost you {0}€ after tax reduction',
        yourMembershipItemWillCostOnly:
          'Your membership will only cost you {0}€ after tax reduction',
        maxPerUser: 'Limited to {0} per person',
        remainingCrowdFundingItems:
          'no more amount available | 1 amount remaining | {remaining} amounts',
        remainingEventItems:
          'no more space available | 1 space remaining | {remaining} spaces remaining',
        remainingMembershipItems:
          'no more space available | 1 space remaining | {remaining} spaces remaining',
        remainingShopItems:
          'no more product available | 1 product remaining | {remaining} products remaining',
        soldout: 'Sold out',
        tooMuchTickets:
          'no more available seats | only 1 seat available | only {remainingEntries} seats available',
        vatRateEvent: 'Registration subject to VAT ({0} %)',
        vatRateMembership: 'Registration subject to VAT ({0} %)',
        vatRateShop: 'Purchase subject to VAT ({0} %)',
        installment: 'Per installment',
        monthly: 'By month',
        paymentFrequencyTotal: '(That is {0} € in total)',
        scheduledPaymentTotal: '(i.e. €0} in total)',
        contribution: 'Contribution',
        extraOptionIsSoldOut:
          'This product is not available because at least one associated mandatory option is currently out of stock.',
        isFull:
          'This is the last product available as the associated mandatory options have been exhausted.',
        maxTiersQuantityReachedPerUser:
          'The maximum number of products payable at once has been reached.'
      },
      vente: {
        vatRate: 'Contribution subject to tax ({0} %)',
        noVatRate: 'Purchase not subject to tax',
        freeAmount: 'Indicate the amount to pay',
        amount: 'Amount (including tax) to pay'
      }
    },
    stepName: {
      donationSelection: 'Donations',
      paymentForm: 'Sale',
      membershipSelection: 'membership selection',
      members: 'Members',
      billingInformation: 'Contact information',
      summary: 'Summary',
      participants: 'Participants',
      contributors: 'Contributors',
      buyers: 'Buyers',
      productsDetails: 'product details',
      ticketSelection: 'ticket selection',
      productSelection: 'product selection',
      type: {
        membership: {
          members: 'members',
          membershipSelection: 'membership selection'
        },
        paymentForm: 'payment amount selection'
      }
    },
    validate: 'Validate',
    validateAndPay: 'Validate and pay',
    ticketsAmount: 'Amount of tickets:',
    seePaymentSchedule: 'See detail',
    hidePaymentSchedule: 'Hide detail'
  },
  campaignBanner: {
    amount: 'Amount (including tax) to be paid:',
    amountMonthly: 'Amount (incl. VAT) to pay per month:'
  },
  campaignHeader: {
    by: 'by',
    inFavour: 'in favour of',
    displayMore: 'Display more',
    displayLess: 'Display less'
  },
  card: {
    memo: {
      amount: 'Total :',
      discount: 'Discount',
      donation: 'Donation',
      free: 'Free',
      options: 'Option | Option x1 | Options x{nbOptions}',
      person: 'person | persons',
      type: {
        shop: {
          title: 'Memo of your products',
          items: 'Product | Product x1 | Products x{nbTickets}'
        },
        crowdfunding: {
          title: 'Memo of your donations',
          items: 'Donation | Donation x1 | Donations x{nbTickets}'
        },
        event: {
          title: 'Memo of your tickets',
          items: 'Ticket | Ticket x1 | Tickets x{nbTickets}'
        },
        membership: {
          items: 'Membership | Membership x1 | Memberships x{nbTickets}',
          title: 'Memo of your memberships'
        }
      }
    },
    organization: {
      contact: {
        email: 'Email',
        phone: 'Phone',
        showContact: 'Show contact information',
        title_1: 'A question?',
        title_2: 'Contact the association:'
      },
      eventLink: 'Online event',
      map: 'Map'
    },
    time: {
      days: 'day | days',
      daysLeft: 'day left | days left',
      hours: 'hour | hours',
      hoursLeft: 'hour remaining | hours remaining',
      minutes: 'minute | minutes',
      minutesLeft: 'minute left | minutes left',
      ended: 'Collection is complete'
    },
    security: {
      text: 'All banking information to process this payment is completely secure. Thanks to SSL encryption of your banking data, you are assured of the reliability of your transactions on HelloAsso.',
      title: '100% secure payment platform'
    },
    seo: 'On the same theme',
    socials: {
      title: {
        campaign: 'share campaign',
        collect: 'Share the collection',
        event: 'Share the event',
        page: 'Share this page',
        shop: 'Share the store'
      }
    }
  },
  checkout: {
    backLabel: 'Return to {0}',
    footer: {
      conditions: 'TOS',
      confidentiality: 'Privacy',
      poweredBy: 'Created and distributed with'
    },
    infos: {
      company: 'Company name',
      email: 'Email',
      firstName: 'First name',
      lastName: 'Last name',
      payAsOrg: 'pay as organization',
      title: 'Payer Information'
    },
    pay: 'Pay {0} €',
    paymentNumber: 'In {0} times',
    scheduler: {
      contribution: 'Of which contribution to HelloAsso',
      for: 'For {0}',
      date: 'On {0}',
      showDetails: 'Show',
      hideDetails: 'Hide',
      ofPayments: 'the due date details',
      title: 'Your direct debit deadlines',
      today: 'Today',
      total: 'Total'
    },
    securedPayment: 'Secure payment',
    security: {
      text: 'All payments using the HelloAsso solution are secure. Bank information to process transactions is protected through SSL encryption.',
      title: 'HelloAsso secures your payment'
    },
    summary: {
      aboutContribution:
        'HelloAsso is powered by the generosity of people like you. Your voluntary contribution of {1} € is our only source of income and allows us to provide our services for free to more associations like {0}.',
      acceptGdpr_1: 'I accept the',
      acceptGdpr_2: 'of the service and I have read the',
      acceptGdprLink_1: 'Terms and Conditions',
      acceptGdprLink_2: 'privacy policy',
      contribution: 'Your contribution to the HelloAsso solidarity model',
      eachPayment: 'Applied to each payment',
      paymentSolution:
        'By choosing our solidarity solution, {0} does not pay any commission or bank fees on its transactions',
      paymentTo: 'Payment to {0}',
      title: 'To be paid today',
      total: 'Total',
      understandContribution:
        'I understand that HelloAsso is a social and solidarity company and confirm that I want to make a financial contribution, optional and modifiable, by finalizing my payment to ensure free service to the association.',
      updateContribution: 'Change the voluntary contribution',
      updateContributionLabel: 'the voluntary contribution'
    },
    title: 'Your payment',
    pageTitle: 'Payment to {0} | Helloasso'
  },
  contributionNotice: {
    text_1:
      'HelloAsso is a social and solidarity company, which provides its payment technologies for free to the',
    text_2:
      'organization. A contribution to the functioning of HelloAsso, modifiable and optional, will be proposed to you before the validation of your payment.',
    enableVolontaryContributionLabel:
      'I understand that HelloAsso is a social and solidarity company and confirm that I want to provide financial support by finalizing my payment*'
  },
  coupon: {
    invalid: 'Invalid code',
    question: 'Do you have a promo code?',
    update: 'Edit coupon code',
    valid: 'Valid code'
  },
  error: {
    cart: {
      ticketsSoldOut: {
        title: 'Too late, some tickets have already been purchased.',
        body: 'Please take your pick from the remaining available seats.'
      },
      optionsSoldOut: {
        title: 'Too late, some options are no longer available.',
        body: 'Please make your choice from the remaining available options.'
      },
      minimumCart: {
        title: 'Minimum cart not reached',
        body: 'Please change your cart amount. It must be free or higher than 0,50€ to authorize your payment.'
      }
    },
    code: {
      504: {
        details:
          'The problem is temporary and is already being resolved by our technical teams. We apologise for any inconvenience caused.',
        subdetails: 'Please try again in a few moments to access your page.',
        message: 'Our server returned an error.',
        title: 'We apologize!'
      },
      400: {
        details:
          'We must have received an alert thanks to you and we are in the process of solving the problem!',
        message: 'The request is invalid.',
        title: 'We apologize!'
      },
      401: {
        details:
          'We must have received an alert thanks to you and we are in the process of solving the problem!',
        message: 'The request is invalid.',
        title: 'We apologize!'
      },
      403: {
        details:
          'We must have received an alert thanks to you and we are in the process of solving the problem!',
        message: 'You do not have the necessary rights to access this page.',
        title: 'We apologize!'
      },
      404: {
        details:
          'Sorry, the page you want to access cannot be found. The link is probably broken or the page no longer exists.',
        message: 'The link is broken or the page has been deleted.',
        title: 'Page not found'
      },
      408: {
        details: 'The operation seems to be in conflict, please try again later.',
        message: 'Our server returned an error.',
        title: 'We apologize!'
      },
      409: {
        details: 'The operation seems to be in conflict, please try again later.',
        message: 'Our server returned an error.',
        title: 'We apologize!'
      },
      413: {
        details: 'An error occurred while transferring your file to our server.',
        message: 'An error occurred while transferring your file to our server.',
        title: 'The file is too large.'
      },
      415: {
        details: 'An error occurred while transferring your file to our server.',
        message: 'An error occurred while transferring your file to our server.',
        title: 'The file has the wrong format'
      },
      500: {
        details:
          'We must have received an alert thanks to you and we are in the process of solving the problem!',
        message: 'Our server returned an error.',
        title: 'We apologize!'
      },
      payment: {
        details: 'The transaction seems to be in conflict, please try again later.',
        message: 'An error occurred during payment.',
        title: 'We apologize!'
      },
      expiration: {
        message: 'Your cart has expired, please try again later.',
        title: 'We apologize!'
      }
    },
    form: {
      details:
        'We must have received an alert thanks to you and we are in the process of solving the problem!',
      message: 'There are errors in the form.',
      title: 'Warning'
    },
    genericError: 'An error has occurred.',
    page: {
      back: 'Back',
      backToHome: 'Return to home',
      redirection_1: 'You can contact us',
      redirection_2: 'here',
      redirection_3: 'about this problem.',
      statusCode: 'Error code: {0}'
    },
    payment: {
      canceled: 'payment has been canceled',
      errorCodes: {
        400: 'Cart is invalid',
        404: 'Cart is unknown or has expired',
        409: 'Cart is invalid',
        gdrpConsentError: 'Please accept the Terms of Service.',
        cgvConsentError: 'You must accept the Terms of Service: {0}',
        ORGANIZATION_WALLET_ERROR: 'Organization validation error',
        tipConsentError:
          'Please check the box to confirm your financial support to HelloAsso. You can also change it above.',
        UNKNOWN: 'An unknown error has occurred.'
      }
    },
    unknown: {
      details:
        'We must have received an alert thanks to you, and we are in the process of resolving the problem!',
      message: 'This error is unknown to us.',
      title: 'We apologize!'
    },
    upload: 'An error occurred while uploading your file to our server.',
    uploadedFileTooLarge: 'The file is too large.',
    uploadedFileWrongFormat: 'The file has the wrong format'
  },
  fiscality: {
    intro:
      'The donation to {0} is eligible for a tax reduction because it meets the general conditions set forth in Articles 200 and 238 bis of the General Tax Code.',
    orgSubtitle: 'Organization / Company',
    title: 'Tax information',
    userSubtitle: 'Individual'
  },
  contributors: {
    title: 'contributors',
    moreButton: 'moreContributors',
    date: 'On {0}'
  },
  footer: {
    cta: 'Read more',
    features: {
      alternativeText:
        'Thanks to an economic model based solely on the voluntary contribution of everyone',
      alternativeTitle: 'alternative',
      humanText: 'Behind the lines of code, there is a whole team committed to each user.',
      humanTitle: 'Human',
      universalText: 'A solution accessible to the greatest number of people, easy to use',
      universalTitle: 'For everyone'
    },
    link: {
      cguOrg: 'CGU Association',
      cguUser: 'CGU User',
      confidentiality: 'Confidentiality',
      cookies: 'Cookie Management',
      guide: 'Association Guide',
      legalNotice: 'Legal Notice'
    },
    mentions: {
      copyright: 'All rights reserved',
      reassurance:
        'The payment platform for associations to seek funding: membership, donations, crowdfunding, online store, ticketing... Get started!',
      lemonway_1:
        'HelloAsso is a payment institution approved by the ACPR in France on 06/08/2023 under number 17678Q.',
      lemonway_2:
        'Our online payment platform is also ESUS (Social Utility Solidarity Enterprise) approved, as well as an Intermediary in Participatory Financing with ORIAS under number 17002233.',
      lemonway_3:
        'HelloAsso is a partner of Lemonway, a payment institution approved by the ACPR in France on 12/24/2012 under number 16568J.',
      fpfMember: 'HelloAsso is a member of the Financement Participatif France association',
      regulatedPlatform: 'Platform regulated by french authorities'
    },
    missions: {
      aboutHa_1: 'HelloAsso is a social utility company. We provide our payment technologies.',
      aboutHa_2: 'free of charge, no fees or commissions,',
      aboutHa_3: 'to more than',
      aboutHa_4: '200,000 French associations.',
      aboutHa_5:
        'The voluntary contributions left to us by Internet users are our only source of income. Thank you for your support!'
    }
  },
  header: {
    accessibilityLabel: 'Open accessibility settings',
    ariaLabel: 'Site navigation',
    menuCloseLabel: 'Close menu',
    menuOpenLabel: 'Open menu',
    organizations: {
      add: 'add an organization',
      title: 'My organizations'
    },
    settings: {
      accessibility: 'Accessibility',
      help: 'Help',
      history: 'My history',
      signout: 'logout',
      profile: 'My profile'
    },
    signin: 'My account',
    signup: 'Register my association'
  },
  jumbotron: {
    subtitle: 'For more information, please contact the organizing association',
    title: {
      isCrowdfundingEnded: 'The fundraising is over!',
      isEventEnded: 'Event is over!',
      isMembershipEnded: 'Membership deadline has passed!',
      isSaleEventEnded: 'Registration deadline has passed!',
      isSaleMembershipEnded: 'Membership deadline has passed!',
      isSaleShopEnded: 'Purchase deadline has passed!',
      isSaleEventNotYetOpen: 'Ticket sales start on {0}',
      isSaleMembershipNotYetOpen: 'Membership opening starts on {0}',
      isSaleShopNotYetOpen: 'Sale starts on {0}',
      isSoldOut: 'All available seats online have been sold!'
    },
    toOrganismPage: 'View the page',
    toSettings: 'Go to my settings'
  },
  loading: {
    subtitle: 'You will be redirected in a few moments...',
    title: 'Please wait'
  },
  metaTags: {
    event: {
      title: {
        Atelier: 'Workshop',
        Compétition: 'Competition',
        Cours: 'Course',
        Concert: 'Concert',
        Festival: 'Festival',
        Fête: 'Party',
        Spectacle: 'Show',
        Jeu: 'Game',
        Conférence: 'Conference',
        Culte: 'Worship',
        Formation: 'Training',
        Bénévolat: 'Volunteering',
        Séjour: 'Trip',
        Exposition: 'Exhibition',
        Vente: 'Sale',
        Sortie: 'Excursion'
      },
      description: {
        descFirst: '{0} - {1} {2} organize 1 {3} to {4}',
        descLast: '{0} {1} organizes 1 {2} to {3} - {4}',
        suffix: '{0} - Book online quickly'
      },
      descriptionWithCity: '{0} organized by {1} to {2} - {3}',
      descriptionWithoutCity: '{0} organized by {1} - {2}'
    },
    membership: {
      title: {
        Atelier: 'Workshop',
        Cours: 'Course',
        'Financement de projet': 'Crowdfunding',
        Compétition: 'Competition',
        Cotisation: 'Membership Fee',
        Bénévolat: 'Volunteering',
        Formation: 'Training',
        Séjour: 'Trip'
      },
      descriptionWithCity: '{0} organized by {1} to {2} - {3}',
      descriptionWithoutCity: '{0} organized by {1} - {2}'
    },
    payment: {
      finalization: {
        confirmation: 'Payment validated',
        pending: 'Payment in progress'
      },
      regularization: {
        confirmation: 'Regularization validated',
        failure: 'Failure of regularization',
        pending: 'Accrual in progress'
      }
    },
    redirect: 'Redirect in progress...',
    crowdfunding: {
      title: 'Crowdfunding',
      descriptionWithCity: '{0} organized by {1} à {2} - {3}',
      descriptionWithoutCity: '{0} organized by {1} - {2}',
      descriptionMasculinWithCity: '{0} organized by {1} à {2} - {3}',
      descriptionMasculinWithoutCity: '{0} organized by {1} - {2}'
    },
    shop: {
      title: 'Sale',
      descriptionWithCity: '{0} organized by {1} to {2} - {3}',
      descriptionWithoutCity: '{0} organized by {1} - {2}'
    },
    donation: {
      description: 'Make a donation {0} - {1}'
    }
  },
  modal: {
    close: 'Close',
    contributionExplication: {
      conclusion: 'All this is only possible thanks to your support.',
      content_1:
        'By ensuring our operation, it allows more than 100,000 associations to have access to a payment solution adapted to their needs, without fees or commissions.',
      content_2:
        'It allows us to employ a team of more than 60 people, each of whom assists associations in the development of their activities',
      content_3: 'It makes it possible to improve our support services and technologies.',
      cta: 'Thank you, I understand',
      intro_1: "That's why we need you.",
      intro_2: 'What is your support for HelloAsso?',
      title: 'Even online payment has a cost'
    },
    contributionSelection: {
      noDonationDescription:
        'You can help us, in your own way, by talking about HelloAsso around you ❤️',
      newModal: {
        title: 'Edit my contribution',
        catchPhrase: {
          noDonation: 'This is our only source of revenues !',
          lowDonation: 'You allow {0} to use HelloAsso for free',
          midDonation: 'Thousands of nonprofits can use HelloAsso for free',
          highDonation: 'You contribute to grow the nonprofits commitment movement'
        },
        textImage: {
          noDonation:
            'Without your support, we would not be able to offer our services to more than 300,000 associations. <b>Your generosity makes all the difference!</b>',
          lowDonation:
            'Thanks ! Thanks to your contribution we can remain 100% free for {0} (zero fees, zero commission).',
          midDonation:
            'Many thanks! You are joining the committed citizens who allow us to create <b>essential and free services for more than 300,000 nonprofits.</b>',
          highDonation:
            'Thanks for supporting our mission! Thanks to you, we can help <b> nonprofits to grow</b> and <b>citizens to get involved</b> with them.'
        },
        contributeUpTo: 'Your contribution :',
        validate: 'Confirm',
        catchUpTips: {
          noThankYou: 'I do not wish to support today',
          addTips: 'I support up to {0} €'
        }
      }
    },
    expiredSession: {
      content_1: 'Your session has expired due to inactivity.',
      content_2: {
        crowdfunding: 'Return to the campaign homepage to continue your payment.',
        event: 'Return to the event homepage to continue your registration.',
        membership: 'Go back to the campaign homepage to continue your membership.',
        shop: 'Return to the store homepage to continue shopping.'
      },
      title: 'Expired session'
    },
    gdprDetails: {
      content_1:
        'The computerized processing on this data is carried out by HelloAsso Company in compliance with the General Data Protection Regulation (GDPR), which came into effect on May 25, 2018.',
      content_2:
        'For more information regarding the processing of your data, we invite you to consult our privacy policy:',
      content_2_linkLabel: 'To the Privacy page',
      content_3: 'For any request to exercise your rights, you can contact our DPO by email at',
      content_4: 'or send a letter to the address of our Company.',
      intro:
        'The personal data you provide in the HelloAsso forms are collected in order to implement and carry out the services we offer on our Platform:',
      listContent_1: 'Create and manage your user profile',
      listContent_2:
        'Create and manage your access to the Platform and to the services available online',
      listContent_3:
        'Send newsletters or any other informative messages related to our news and/or any evolution of our Services',
      listContent_4: 'To compile statistics on the use of and visits to our services',
      listContent_5: 'To meet our legal and regulatory obligations',
      title: 'Personal data'
    },
    login: {
      connect: 'Login',
      email: 'Email',
      errors: {
        login: {
          details: 'The connection was refused by the server.',
          message: 'Please check your login and password.',
          title: 'An error has occurred.'
        }
      },
      forgotPassword: 'Forgot your password?',
      password: 'Password',
      title: 'Login to HelloAsso'
    },
    report: {
      emailInput: {
        error: 'Your email must follow the following format: "jean@exemple.fr".',
        label: 'Your email',
        placeholder: 'Example: jean@exemple.com'
      },
      errorToast: {
        body: 'Your report could not be sent, please try again later.',
        title: 'An error occurred.'
      },
      messageInput: {
        error: 'Please fill in the reason for your report.',
        label: 'Your message',
        placeholder: 'The reason for your report.'
      },
      reasonRequired: 'You must select a reason.',
      reasons: {
        DISLIKE: "I don't like it",
        INTELLECTUAL_PROPERTY: 'I think this is an unauthorized use of my intellectual property',
        OFF_TOPIC: "I don't think it belongs on HelloAsso",
        SPAM: 'This is unwanted content'
      },
      successToast: {
        body: 'Thank you for your contribution.',
        title: 'Report sent.'
      },
      title: 'Report this page.',
      why: 'Why do you want to report this page?'
    }
  },
  option: {
    required: '(required)',
    available: 'Out of stock | 1 available | {available} available',
    free: 'Free',
    vat: 'VAT {0} %'
  },
  optionalDonation: {
    Shop: {
      addDonation_1: 'Would you like to add a donation to',
      addDonation_2: 'in addition to your purchases?'
    },
    Event: {
      addDonation_1: 'Would you like to add a donation to',
      addDonation_2: 'in addition to your registration?'
    },
    Membership: {
      addDonation_1: 'Would you like to make a donation to',
      addDonation_2: 'in addition to your membership?'
    },
    freeDonationPlaceholder: 'Amount of your choice',
    noDonation: 'No donation',
    chooseDonation: 'Choose an amount',
    accessibilityRadio: 'Button to choose',
    accessibilityRadioCustom: 'the amount of your choice'
  },
  organismType: {
    association: 'The association',
    endowmentFund: 'The endowment fund',
    foundation: 'The foundation'
  },
  payment: {
    aboutContribution:
      "The HelloAsso model guarantees that associations will receive the full amount of payments like yours. We don't charge any fees, no commission, our only source of income is not from advertising but from the generosity of people like you who find our service useful. Your support is essential and allows our team to help even more associations for free. Thank you to those who contribute to the growth of a solidary model of the web, and thank you to those whom this message could move to action.",
    backToCampaign: 'Back to the campaign',
    backToEvent: 'Back to the ticketing',
    backToForm: 'Back to the form',
    backToShop: 'Back to the store',
    cardNumber: 'Card number',
    clickOnChosenSocialNetwork: 'Click on the icon of the chosen social network',
    confirmationMail: 'You will receive a confirmation email at the following address:',
    cvc: 'Visual cryptogram',
    cvcDetails: 'The 3 digits on the back of your credit card',
    dependingOnYourBank:
      "Depending on your bank, you may be redirected to your bank's authentication page before validating your payment.",
    downloadDocument: 'Download your documents',
    enterCardInfos: 'Please enter the card information.',
    error:
      'Rest assured, no amount has been debited and we invite you to renew your payment. To help you finalize your payment, we invite you to <a href="https://centredaide.helloasso.com/particulier?question=comment-resoudre-un-echec-de-paiement-ou-refus-de-paiement" target="_blank" :title="our online help center">consult our online help</a> or contact your bank.',
    errorCode: 'Error code: {0}',
    expirationDate: 'Expiration date',
    fiscalReceipt: 'Tax receipt',
    getOrders: 'Find your orders at any time in',
    getReceipt: 'Your documents are available at any time in',
    getHelp: 'Get help',
    goBack: 'Go back to the previous step',
    invitePeople: 'Invite your loved ones to join you:',
    lastStep: 'One last step to support {0}, spread the word!',
    loading: 'Loading...',
    madeBy: 'organized by {0}',
    mandatoryFields: '*required fields',
    membershipCard: 'membership card',
    nextPayment: 'Your next payment of {0}€ is due on {1}.',
    oneOutOfTwo: '1 out of 2 people participate as a result of a referral.',
    order: 'Order #{0}',
    pay: 'Pay {0}€',
    payTerm: 'Pay my due date',
    paymentFailed: 'Your payment was not successful.',
    paymentInfos: 'Payment Information',
    paymentRefused: 'Your payment request has been refused.',
    paymentSuccess: 'Your payment of {0}€ was successful.',
    pleaseCheckInfos: 'Check your balance or change your credit card to retry your charge.',
    pleaseTryAgain:
      'We invite you to renew your payment, and to contact your bank if the problem persists.',
    paymentReceipt: 'Certificate of payment',
    regularizationSuccess: 'Your payment of {0}€ has been regularized.',
    relanchPayment: 'Retry the payment',
    sharingIsCaring: 'To share is to support: send this form to your contacts',
    summary: {
      amount: 'Amount',
      contribution: 'including contribution to HelloAsso',
      date: 'taken from',
      outOf: '{0} on {1}',
      paymentNumber: 'due date',
      refused: 'Refused by the bank',
      status: 'Status',
      title: 'Summary:'
    },
    thanks: 'Message from {0}:',
    ticket: 'ticket',
    title: {
      confirmation: 'Payment Confirmation',
      pending: 'Payment',
      regularizationCancellation: 'Cancellation of the regularization of your payment',
      regularizationConfirmation: 'Confirmation of the regularization of your due date',
      regularizationFailure: 'Failure of the regularization of your due date',
      regularizationPending: 'Regularization of your due date {0}'
    },
    toHelpCenter: 'To the Help Center',
    totalAmountContribution: 'including contribution to HelloAsso',
    totalAmountToPay: 'Total amount to pay',
    updateTickets: 'Edit my ticket',
    useNewPaymentMethod:
      'In case of modification, register my new card to pay the next installments.',
    youCanBeProud: 'Invite your friends to join you :',
    youCanHelp: 'You can help {0} by sharing his initiative {1}.',
    youCanTryAgain:
      'If you want, you can try again. If you have any problems, please contact your bank.',
    yourPaymentHasBeenRefused: 'Your payment attempt was not successful.',
    paymentIntentAuthenticationFailure: {
      title: 'Your payment could not be finalized.',
      content:
        'This payment failure is related to the entry of your credit card details, your cryptogram or your 3D Secure code.\r\nTo help you finalize your payment, we invite you to <a href="https://centredaide.helloasso.com/particulier?question=comment-resoudre-un-echec-de-paiement-ou-refus-de-paiement" target="_blank" :title="our online help center">consult our online help</a>.\r\nRest assured, no amount has been debited following your payment attempt.'
    },
    yourUserspace: 'yourUserspace.',
    thanksSupport: {
      supporting: {
        title: 'Thank you for supporting HelloAsso in its mission ❤️',
        paragraph:
          "Thanks to your voluntary contribution, we pay no commission on your payment and benefit from HelloAsso's free online payment tools. Your supporters are their only source of income, thank you for them."
      },
      notSupporting: {
        title: 'Your support for our association is priceless, but it does come at a cost 💳',
        paragraph:
          'On another platform, we would have to pay a fee on your payment and would not be able to obtain our entire collect. <br class="hideDesktop" /><br class="hideDesktop"/><strong>By choosing HelloAsso, we benefit from a free service that enables us to offer you online payment.</strong><br /><br />All this is only possible thanks to the voluntary contributions you can leave to support HelloAsso in its mission. Without them, <strong>42%</strong> of associations like ours would give up online payment for lack of funds.'
      }
    },
    HASocials:
      'Follow HelloAsso on Instagram to fill up on impact projects, sports, culture, nature... 👇',
    validatedPayment: {
      title: 'Your payment has been validated.',
      content:
        'You will receive your payment confirmation and documents by email within 15 to 60 minutes.\r\nIf you do not receive a confirmation by email, we invite you to <a href="https://www.helloasso.com/contactez-nous" target="_blank" :title="">contact us</a>.'
    },
    yourUserspace: 'yourUserspace.'
  },
  share: {
    buttonLabel: {
      copyUrl: 'Copy URL to clipboard',
      email: {
        campaign: 'Share campaign by email',
        collect: 'Share the collect by email',
        event: 'Share the event by email',
        page: 'Share this page by email',
        shop: 'Share the store by email'
      },
      facebook: {
        campaign: 'Share the campaign on Facebook',
        collect: 'Share the collection on Facebook',
        event: 'Share the event on Facebook',
        page: 'Share this page on Facebook',
        shop: 'Share the store on Facebook'
      },
      linkedin: {
        campaign: 'Share campaign on LinkedIn',
        collect: 'Share the collection on LinkedIn',
        event: 'Share the event on LinkedIn',
        page: 'Share this page on LinkedIn',
        shop: 'Share the store on LinkedIn'
      },
      messenger: {
        campaign: 'Share the campaign on Messenger',
        collect: 'Share the collect on Messenger',
        event: 'Share the event on Messenger',
        page: 'Share this page on Messenger',
        shop: 'Share the store on Messenger'
      },
      twitter: {
        campaign: 'Share the campaign on Twitter',
        collect: 'Share the collection on Twitter',
        event: 'Share the event on Twitter',
        page: 'Share this page on Twitter',
        shop: 'Share the store on Twitter'
      }
    },
    prefilledText: {
      email: {
        checkout: '🗓️ Finalize your payment now via our dedicated form {0} on HelloAsso',
        crowdfunding: '🗓️ Participate now in our campaign {0} on HelloAsso',
        donation: '🗓️ Donate now via our donation form {0} on HelloAsso',
        event: '🗓️ Register now for the event {0} on HelloAsso',
        membership: '🗓️ Join now via our {0} campaign on HelloAsso',
        paymentform: '🗓️ Pay now via our dedicated form {0} on HelloAsso',
        shop: '🗓️ Buy now via our store {0} on HelloAsso'
      },
      twitter: {
        checkout: '🗓️ Finalize your payment now via our dedicated form {0} : {1} @helloasso',
        crowdfunding: '🗓️ Participate now in our campaign {0} : {1} @helloasso',
        donation: '🗓️ Donate now via our donation form {0} : {1} @helloasso',
        event: '🗓️ Register now for the event {0} : {1} @helloasso',
        membership: '🗓️ Join now via our campaign {0} : {1} @helloasso',
        paymentform: '🗓️ Pay now via our dedicated form {0} : {1} @helloasso',
        shop: '🗓️ Buy now on our store {0} : {1} @helloasso'
      }
    }
  },
  toast: {
    copyUrl: {
      title: 'URL copied! 🎉',
      body: 'You can now use it to share your campaign.'
    },
    failedRedirection: {
      title: 'Arf! 😕',
      body: 'The redirect to the payment failed. Please try again.'
    },
    usedToken: {
      title: 'Sorry! 😧',
      body: 'The link you wish to access is no longer valid or has already been used.'
    },
    prospect: {
      title: 'Email saved',
      body: 'The {0} association will get back to you soon.'
    }
  },
  tooltip: {
    emailConfirmation:
      'Your email address will allow us to send you your confirmation email, along with your credentials.'
  },
  total: {
    monthlyDonation: 'Amount of payment each month',
    amount: 'Amount to be paid:',
    amountTTC: 'Amount including VAT :',
    amountWithVAT: 'Including VAT:',
    toBePaidToday: 'To be paid today:',
    type: {
      shop: {
        amountTickets: 'Amount of purchases:',
        amountTicketsModalities: 'Amount incl. taxes',
        subtitle: 'to pay for your purchases',
        today: 'to pay today',
        installmentText1: 'then  {0} € next time',
        installmentText2: '{0} € donated to the association {1}',
        installmentText3: 'and',
        installmentText4: '€ operating assistance for HelloAsso'
      },
      event: {
        amountTickets: 'Amount of tickets:'
      },
      membership: {
        amountTickets: 'Amount to be paid for the duration of the membership:'
      },
      crowdfunding: {
        amountTickets: 'Amount to pay:'
      }
    }
  },
  validators: {
    youMustChooseAStatus: 'You must choose a legal status from the list',
    optionIsMandatory: 'This option is mandatory',
    date: 'This date is not valid',
    email: 'Your email address is invalid',
    emailMatch: 'The email address does not match the one provided in the previous field.',
    maxAmount: 'The value must be a number less than {0}€',
    minAmount: 'The value must be a number greater than {0}€',
    nameChars:
      'Your {0} cannot contain any of the following characters :  , . \\ / * ² & # @ ^ ¨ _ -- + * > <',
    nameConsecutiveChars: 'Your {0} must not contain 3 consecutive identical characters.',
    nameContainsForbidden:
      'Your {0} cannot contain firstname, lastname, unknown, first_name, last_name or anonymous.',
    nameDifferent: 'Your first and last names must be different.',
    nameIsForbidden: 'Your {0} is invalid.',
    nameLatin: 'Your {0} must be written using the Latin alphabet.',
    nameLength: 'Your {0} must not contain a single character.',
    nameNoNumbers: 'Your {0} must not contain any numbers.',
    nameVowels: 'Your {0} must contain at least one vowel.',
    password: 'Your password is too simple.',
    passwordMatch: 'The passwords are not identical.',
    phone: 'This phone number is invalid.',
    required: 'All required fields must be filled in',
    zipCode:
      'Your postal code is not correct, HelloAsso is only available for associations based in France',
    siren: 'Your SIREN code must be 9 digits long.',
    maxLength: 'The field is limited to {0} characters maximum',
    birthdate: 'The date of birth is not valid.'
  },
  widget: {
    form: {
      crowdfunding: 'Crowdfunding',
      event: 'Ticketing',
      membership: 'Membership',
      shop: 'Shop',
      paymentform: 'Sale',
      donation: 'Donation'
    },
    button: {
      widget: 'Participate',
      crowdfunding: 'Make a donation',
      event: 'Participate',
      membership: 'Join',
      shop: 'buy',
      paymentform: 'Make a payment',
      donation: 'Make a donation'
    },
    thumbnail: {
      participate: 'I participate',
      contribute: 'I contribute'
    },
    poweredBy: 'Created and distributed with',
    updateCookies: 'Cookie management',
    currencyUnit: '{0}€',
    seeMore: 'See more'
  },
  cartModalities: {
    title: 'Payment facilities',
    radio: {
      once: 'Pay in full now',
      multiple: 'Pay in several installments'
    },
    chooseSuitableModalities: 'Choose the payment terms suited to your needs',
    schedule: 'Schedule',
    today: 'Today',
    details: '(View details)',
    modal: {
      header: 'Informations on your first payment',
      disclaimer: 'The following items must be paid immediately.',
      options: 'Your options',
      donations: 'Your donations',
      donationTo: 'Donate to',
      ineligibleTiers: 'Your prices',
      eligibleTiers: 'First expiry of eligible rates'
    },
    totalCart: 'Total cart'
  },
  reassurance: {
    securePayment: 'Secure payment'
  },
  voluntaryCard: {
    title: 'Why support HelloAsso ?',
    text1: 'Thanks to your support, we are 100% free for ',
    text2:
      'and for thousands associations. Participate in the success of our mission by leaving a contribution at the time of your payment.'
  }
}
